/**
 * Class element
 * Accordion Slider component
 * Display an accordion with a slider image or video
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React, { Component } from "react"
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState} from "react-accessible-accordion"
import { IconContext } from "react-icons"
import { BsFillCaretRightFill } from "react-icons/bs"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import "../styles/components/accordionslider.scss"

/* Declaration class */
class Accordionslider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      counter: 0,
      expandedItem: 1,
      indexActive: 1,
      width: 0,
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
    const thisBoundedIncrementer = this.incrementCounter.bind(this)
    this.timer = setInterval(thisBoundedIncrementer, 1000)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  incrementCounter() {
    const { counter } = this.state

    if (counter % 6 === 0) {
      if(this.state.expandedItem === 4) {
        this.setState({
          counter: counter + 1,
          expandedItem: 1,
          width: this.state.width,
          indexActive: this.state.indexActive
        })
      } else {
        this.setState({
          counter: counter + 1,
          expandedItem: this.state.expandedItem + 1,
          width: this.state.width,
          indexActive: this.state.indexActive
        })
      }
    }else{
      this.setState({
        counter: counter + 1,
        expandedItem: this.state.expandedItem,
        width: this.state.width,
        indexActive: this.state.indexActive
      })
    }
  }

  handleWindowSizeChange = () => {
    if(window.innerWidth !== this.state.width) {
      this.setState({
        counter: this.state.counter,
        expandedItem: this.state.expandedItem,
        indexActive: this.state.indexActive,
        width: window.innerWidth,
      })
    }
  }

  onChangeAccordion(index) {
    this.setState({
      counter: this.state.counter,
      expandedItem: this.state.expandedItem,
      indexActive: index+1,
      width: this.state.width,
    })
  }

  clickOnAccordionHeading = (e, indexAccordion) => {
    clearInterval(this.timer)
    this.setState({
      counter: 0,
      expandedItem: -1,
      indexActive: indexAccordion,
      width: this.state.width,
    })
  }

  render () {
    const arrItems = this.props.arrItems
    var indexActiveAccordion = (this.state.expandedItem !== -1) ? this.state.expandedItem : this.state.indexActive
    var itemOfVisual = arrItems.filter(item => item.index === indexActiveAccordion)
    const isTablet = this.state.width <= 1112
    //const preExpandedSlider = [1]

    return (
      <div className="wrap-accordion-slider">
        <div className="accodrion-slider-content">
          <Accordion /*preExpanded={preExpandedSlider}*/ allowZeroExpanded >
            {arrItems.map((item, index) => (
              <AccordionItem key={`accordion-slider-${index}`} uuid={item.index} dangerouslySetExpanded={(item.index === indexActiveAccordion) && true} className={(item.index === indexActiveAccordion) ? "accordion__item active-accordion":"accordion__item"} onClick={() => this.onChangeAccordion(index)}>
                <AccordionItemHeading onClick={(e) => this.clickOnAccordionHeading(e, item.index)}>
                  <AccordionItemButton >
                    <div className="box-flex-title">
                      <GatsbyImage image={item.icon.localFile.childImageSharp.gatsbyImageData} alt={`icon ${item.title}`} />
                      <h3>{item.title}</h3>
                    </div>
                    <AccordionItemState>
                    {({ expanded }) =>  (expanded ?
                      (<span className="span-carret active-accordion">
                        <IconContext.Provider value={{ className: "icon-carret" }}>
                          <BsFillCaretRightFill />
                        </IconContext.Provider>
                      </span>) :
                      (<span className="span-carret">
                        <IconContext.Provider value={{ className: "icon-carret" }}>
                          <BsFillCaretRightFill />
                        </IconContext.Provider>
                      </span>))}
                  </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="box-content-accordion" dangerouslySetInnerHTML={{ __html:item.text}}/>
                  {isTablet && (
                    <div className="accordion-slider-visual-tablet">
                      <GatsbyImage image={item.visual.localFile.childImageSharp.gatsbyImageData} alt={`visual ${item.title}`} />
                    </div>
                  )}
                  <div className="box-content-cta-accordion">
                    <Ctabuttonwitharrow ctaLabel={item.ctaLabel} ctaLink={item.ctaLink} specificStyle="text-arrow" />
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              )
            )}
          </Accordion>
        </div>
        {!isTablet && (
          <div className="accordion-slider-visual">
            {itemOfVisual.map((item) => (
              <GatsbyImage image={item.visual.localFile.childImageSharp.gatsbyImageData} alt={`visual ${item.title}`} />
            ))}
          </div>
        )}
      </div>
    )
  }
}

/* Export function */
export default Accordionslider

