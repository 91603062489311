/**
 * Const with params element
 * CTA Button with arrow SVG component
 * Display a link as a button with arrow
 */
/* Import section */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { IconContext } from "react-icons"
import { BsArrowRight, BsArrowLeftShort } from "react-icons/bs"
import "../styles/components/ctabuttonwitharrow.scss"

/* Declaration function */
const Ctabuttonwitharrow = ({ idCta, classCta, ctaLabel, ctaLink, colorStyle, specificStyle, extLink, arrow }) => {
  const defaultStyle = "cta-button-with-arrow"

  if(extLink) {
    return (
      <a id={idCta} className={`${defaultStyle} ${colorStyle} ${specificStyle} ${classCta}`} href={ctaLink} target="_blank" rel="noreferrer" aria-label={ctaLabel}>
        {arrow === "left" ? (
          <WrapspanbuttonLeft ctaLabel={ctaLabel} />
         ) : (
          <Wrapspanbutton ctaLabel={ctaLabel} />
        )}
      </a>
    )
  } else {
    return (
      <Link id={idCta} className={`${defaultStyle} ${colorStyle} ${specificStyle} ${classCta}`} to={`${ctaLink}/`} aria-label={ctaLabel}>
        {arrow === "left" ? (
          <WrapspanbuttonLeft ctaLabel={ctaLabel} />
         ) : (
          <Wrapspanbutton ctaLabel={ctaLabel} />
        )}
      </Link>
    )
  }
}

/* Function propTypes declaration */
Ctabuttonwitharrow.propTypes = {
  idCta: PropTypes.string,
  classCta: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  colorStyle: PropTypes.string,
  specificStyle: PropTypes.string,
  extLink: PropTypes.bool
}

Ctabuttonwitharrow.defaultProps = {
  idCta: "",
  classCta: "",
  ctaLabel: "Click here",
  ctaLink: "/",
  colorStyle: "",
  specificStyle: "",
  extLink: false
}

/* Export function */
export default Ctabuttonwitharrow

/* Wrapspanbutton function */
const Wrapspanbutton = ({ ctaLabel }) => {
  return (
    <span className="wrap-spanbutton">
      <span className="spanbutton-label">{ctaLabel}</span>
      <span className="spanbutton-arrow">
        <IconContext.Provider value={{ className: "icon-arrow" }}>
          <BsArrowRight />
        </IconContext.Provider>
      </span>
    </span>
  )
}

/* Wrapspanbutton function */
const WrapspanbuttonLeft = ({ ctaLabel }) => {
  return (
    <span className="wrap-spanbutton left">
      <span className="spanbutton-arrow">
        <IconContext.Provider value={{ className: "icon-arrow" }}>
          <BsArrowLeftShort />
        </IconContext.Provider>
      </span>
      <span className="spanbutton-label">{ctaLabel}</span>
    </span>
  )
}