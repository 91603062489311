/**
 * Const with params element
 * Block top info component
 * Display a block of last blog or news or other info
 */
/* Import section */
import React from "react"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import "../styles/components/blocktopinfo.scss"

/* Declaration function */
const Blocktopinfo = ( { typeTopInfo, titleTopInfo, ctaLabelTopInfo, ctaLinkTopInfo} ) => {
  return (
    <div className="wrap-topinfo">
      <div className="box-topinfo">
        <p className="type-topinfo">{typeTopInfo}</p>
        <h2>{titleTopInfo}</h2>
      </div>
      <Ctabuttonwitharrow
        ctaLabel={ctaLabelTopInfo}
        ctaLink={ctaLinkTopInfo}
        specificStyle="cta-topinfo" />
    </div>
  )
}

/* Export function */
export default Blocktopinfo

