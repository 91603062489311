/**
 * Const with params element
 * Hero homepage component
 * Display the hero of the homepage
 */
/* Import section */
import React from "react"
import Boxctavideo from "../components/boxctavideo"
import Sliderhero from "../components/sliderhero"
import Doubleshape from "../images/svg/doubleshape.inline.svg"
import "../styles/components/herohomepage.scss"

/* Declaration function */
const Herohomepage = ( {  h1title, arrIntro, videoHero} ) => {
  return (
    <section className="section-hero top-section">
      <div className="shape-hero">
        <Doubleshape />
      </div>
      <div className="wrap-hero">
        <div className="box-slider-hero">
          <h1>{h1title}</h1>
          <Sliderhero keySlider="slider-homepage" arrText={arrIntro} />
        </div>
        <div className="videolightbox-hero">
          <Boxctavideo index="homepage" text_cta="Watch Video" video_title="hello health US" video_url={videoHero} classbtn_video="round" />
        </div>
      </div>
    </section>
  )
}

/* Export function */
export default Herohomepage

