/**
 * Const with params element
 * Card with CTA component
 * Display a card with a cta in the bottom
 */
/* Import section */
import PropTypes from "prop-types"
import React from "react"
import Ctabuttonwitharrow from "./ctabuttonwitharrow"
import "../styles/components/cardwithcta.scss"

/* Declaration function */
const Cardwithcta = ( { titleCard, textCard, ctaLabelCard, ctaLinkCard } ) => {
  return (
    <div className="card-withcta">
      <div className="wrap-text-card">
        <h3>{titleCard}</h3>
        <div className="text-card" dangerouslySetInnerHTML={{ __html:textCard}} />
      </div>
      <div className="cta-bottom-card">
        <Ctabuttonwitharrow ctaLabel={ctaLabelCard} ctaLink={ctaLinkCard} specificStyle="bottom-square" />
      </div>
    </div>
  )
}

/* Function propTypes declaration */
Cardwithcta.propTypes = {
  titleCard: PropTypes.string,
  textCard: PropTypes.string,
  ctaLabelCard: PropTypes.string,
  ctaLinkCard: PropTypes.string,
}

Cardwithcta.defaultProps = {
  titleCard: "Title Card",
  textCard: "Text Card",
  ctaLabelCard: "Read More",
  ctaLinkCard: "",
}


/* Export function */
export default Cardwithcta
