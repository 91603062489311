/**
 * Class element
 * Box video promo component
 * Display a box with a big play video CTA and video in lightbox
 */
/* Import section */
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import React, { Component } from "react"
import { IconContext } from "react-icons"
import { BsFillPlayFill, BsX } from "react-icons/bs"
import Video from "../components/video"
import "../styles/components/boxctavideo.scss"

/* Declaration function with StaticQuery */
class Boxctavideo extends Component {

  targetElement = null
  video = null

  constructor(props) {
    super(props)
    this.state = {
      showLightbox: false
    }
  }

  componentDidUpdate() {
    var indexVideo = this.props.index
    this.targetElement = document.querySelector('#videoLightbox-'+indexVideo)
    this.currentVideoIframe = document.querySelector( '#videoLightbox-'+indexVideo+' .video-foreground iframe')
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  manageLightbox(boolStateLightbox){
    this.setState({ showLightbox: boolStateLightbox })
    var header = [].slice.call((document.getElementsByClassName("main-header")))
    var boxCtaVideo = [].slice.call((document.getElementsByClassName("box-cta-video")))

    if(this.targetElement !== null) {
      if(boolStateLightbox === true) {
        disableBodyScroll(this.targetElement);
        this.currentVideoIframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'playVideo' }), '*')
        header[0].classList.add("lowzindex")
        boxCtaVideo[0].classList.remove("relative")
      }else{
        enableBodyScroll(this.targetElement);
        this.currentVideoIframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'stopVideo' }), '*')
        header[0].classList.remove("lowzindex")
        boxCtaVideo[0].classList.add("relative")
      }
    }

  }

  render () {
    const textCta = this.props.text_cta
    const titleVideo = this.props.video_title
    const urlVideo = this.props.video_url
    const classBoxCtaVideo = this.props.classBoxCtaVideo
    const classNameBtnVideo = this.props.classbtn_video
    const classNameColorBtn = this.props.classbtn_color
    var indexVideo = this.props.index

    return (
      <div className={`box-cta-video relative ${classBoxCtaVideo}`} >
        <div className={`cta-video ${classNameBtnVideo}`} onClick={() => this.manageLightbox(true)} aria-label="Open the lightbox"onKeyDown={() => this.manageLightbox(true)} role="button" tabIndex={0}>
          <button onClick={() => this.manageLightbox(true)} aria-label="Open the lightbox" className={`button-player-video ${classNameBtnVideo} ${classNameColorBtn}`}>
            <span className="wrap-player">
              <IconContext.Provider value={{ className: "svg-player" }}>
                <BsFillPlayFill />
              </IconContext.Provider>
              {classNameBtnVideo === "square" && <span className="text-cta-square">{textCta}</span>}
            </span>
          </button>
          {(classNameBtnVideo === "round" || classNameBtnVideo === "roundsmall")  && <p>{textCta}</p>}
        </div>
        {(this.state.showLightbox === true) &&
          <div id={`videoLightbox-${indexVideo}`} className="video-lightbox" onClick={() => this.manageLightbox(false)} onKeyDown={() => this.manageLightbox(false)} role="button" tabIndex={0} aria-label="Close the lightbox">
          <button id="player-videopromo" className="button-close-lightbox" onClick={() => this.manageLightbox(false)} aria-label="Close the lightbox">
            <IconContext.Provider value={{ className: "icon-closed-lightbox" }}>
              <i aria-hidden="true"><BsX /></i>
            </IconContext.Provider>
          </button>
          <Video videoTitle={titleVideo} videoSrcURL={urlVideo} />
        </div>
        }

      </div>
    )
  }
}

/* Export function */
export default Boxctavideo
