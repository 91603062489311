/**
 * Class element
 * Accordion with CTA component
 * Display an accordion with CTA in each element
 */
/* Import section */
import React, { Component } from "react"
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState} from 'react-accessible-accordion'
import { IconContext } from "react-icons"
import { BsChevronDown } from "react-icons/bs"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import "../styles/components/accordionwithcta.scss"

/* Declaration class */
class Accordionwithcta extends Component {
  constructor(props) {
    super(props)
    this.state = {
      indexActive: null
    }
  }

  onChangeAccordion(index) {
    if(index === this.state.indexActive) {
      index = null
    }
    this.setState({
      indexActive: index
    })
  }

  render () {
    const arrItems = this.props.arrItems


    return (
      <Accordion className="accordion accordion-with-cta" allowZeroExpanded>
        {arrItems.map((item, index) => (
          <AccordionItem key={`accordion-${item.title}-${index}`} uuid={item.index} className={(item.index === this.state.indexActive) ? "accordion__item active-accordion":"accordion__item"} onClick={() => this.onChangeAccordion(index)}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h3>{item.title}</h3>
                <AccordionItemState>
                  {({ expanded }) =>  (expanded ?
                    (<span className="span-chevron active-accordion">
                      <IconContext.Provider value={{ className: "icon-chevron" }}>
                        <BsChevronDown />
                      </IconContext.Provider>
                    </span>) :
                    (<span className="span-chevron">
                      <IconContext.Provider value={{ className: "icon-chevron" }}>
                        <BsChevronDown />
                      </IconContext.Provider>
                    </span>))}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="box-content-accordion" dangerouslySetInnerHTML={{ __html:item.text}}/>
              <Ctabuttonwitharrow ctaLabel={item.ctaLabel} ctaLink={item.ctaLink} specificStyle="bottom-square" />
            </AccordionItemPanel>
          </AccordionItem>
          )
        )}
      </Accordion>
    )
  }
}



/* Export function */
export default Accordionwithcta

