/**
 * Homepage page
 */
/* Import section */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Accordionslider from "../components/accordionslider"
import Accordionwithcta from "../components/accordionwithcta"
import Blocktopinfo from "../components/blocktopinfo"
import Cardwhitcta from "../components/cardwithcta"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import Herohomepage from "../components/herohomepage"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Titledecorative from "../components/titledecorative"
import Doubleshape from "../images/svg/doubleshape.inline.svg"
import "../styles/pages/home.scss"

/* Page function declaration */
const IndexPage = ({data}) => {

  //Const data
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageHome
  const dataLastArticle = data.lastestArticle

  //Array for SliderHero
  let arrTextHero = []
  dataAcf.repeaterTextH1.map(texth1 => (
    arrTextHero.push({textHero: texth1.textHero})
  ))

  //Array for accordion with cta
  let arrBenefits = []
  let arrProducts = []
  let arrSpecialties = []


  dataAcf.repeaterBenefits.map((benefit, index)=> (
    arrBenefits.push({index: index, title: benefit.titleH3Benefits, text: benefit.textH3Benefits, ctaLabel: benefit.ctaLabelH3Benefits, ctaLink: benefit.ctaLinkH3Benefits})
  ))

  dataAcf.repeaterProducts.map((product, index) => (
    arrProducts.push({index: index+1, icon: product.iconProducts, title: product.titleH3Products, text: product.textH3Products, ctaLabel: product.ctaLabelH3Products, ctaLink: product.ctaLinkH3Products, visual: product.visualProducts})
  ))

  dataAcf.repeaterSpecialties.map((specialty, index) => (
    arrSpecialties.push({index: index, title: specialty.titleH3Specialties, text: specialty.textH3Specialties, ctaLabel: specialty.ctaLabelH3Specialties, ctaLink: specialty.ctaLinkH3Specialties})
  ))

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        slickslider= "slick"
        datastructured="intro"
      />

      {/* Hero section */}
      <Herohomepage
        h1title={dataAcf.titleH1Homepage}
        arrIntro={arrTextHero}
        videoHero={dataAcf.heroVideo}
      />

      {/*Latest blog or news section */}
      <section className="section-latestarticle">
        <Blocktopinfo
          typeTopInfo={dataLastArticle.edges[0].node.categories.nodes[0].name}
          titleTopInfo={dataLastArticle.edges[0].node.title}
          ctaLabelTopInfo="Read Article"
          ctaLinkTopInfo={`/${dataLastArticle.edges[0].node.categories.nodes[0].slug}/${dataLastArticle.edges[0].node.slug}`}/>
      </section>

      {/*Specialties section */}
      <section className="section-specialties">
        <div className="wrap-maincontent">
          <Titledecorative title={dataAcf.titleH2Specialties} />
          <div className="flex-box">
            <div className="intro">
              <div className="intro-content first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textH2Specialties}}/>
              <div className="box-cta">
                <h3>{dataAcf.titleBoxCtaSpecialties}</h3>
                <div className="box-cta-content" dangerouslySetInnerHTML={{ __html:dataAcf.textBoxCtaSpecialties}}/>
                <div className="box-cta-separator"></div>
                <div className="box-cta-link">
                  <Ctabuttonwitharrow ctaLabel="Contact Us" ctaLink="/contact-us" specificStyle="text-arrow" colorStyle="white-format" />
                </div>
              </div>
            </div>
            <div className="wrap-accordion">
              <Accordionwithcta key="accordion-specialties" arrItems= {arrSpecialties} />
            </div>
          </div>
        </div>
      </section>

      {/*Products section */}
      <section className="section-products">
        <div className="wrap-maincontent white">
          <Doubleshape className="shape-1 shape-tiny" />
          <Doubleshape className="shape-2 shape-big" />
          <Doubleshape className="shape-3 shape-tiny" />
          <Titledecorative title={dataAcf.titleH2Products} />
          <div className="flex-box">
            <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textH2Products}}/>
          </div>

          <Accordionslider key="accordion-products" textIntro={dataAcf.textH2Products} arrItems= {arrProducts} />
        </div>
      </section>

      {/*Benefits section */}
      <section className="section-benefits">
        <div className="wrap-maincontent">
          <Titledecorative title={dataAcf.titleH2Benefits} />
          <div className="flex-box">
            <div className="image-ambiance">
              <GatsbyImage image={dataAcf.imageBenefits.localFile.childImageSharp.gatsbyImageData} alt="benefits Hello Health ambiance" />
            </div>
            <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textH2Benefits}}/>
            <div className="wrap-accordion">
              <Accordionwithcta key="accordion-benefits" arrItems= {arrBenefits} />
            </div>
          </div>
        </div>
      </section>

      {/*Services section */}
      <section className="section-services">
        <div className="wrap-maincontent">
          <Titledecorative title={dataAcf.titleH2Services} />
          <div className="intro intro-services first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textH2Services}}/>
          <div className="box-services">
            {dataAcf.repeaterServices.map( (service, index) =>
              <Cardwhitcta
                key={index}
                titleCard={service.titleH3Services}
                textCard={service.textH3Services}
                ctaLabelCard={service.ctaLabelH3Services}
                ctaLinkCard={service.ctaLinkH3Services} />
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "homepage"}) {
      acfPageHome {
        titleH1Homepage
        repeaterTextH1 {
          textHero
        }
        heroVideo
        titleH2Benefits
        textH2Benefits
        imageBenefits {
          localFile {
            childImageSharp {
              gatsbyImageData (layout: CONSTRAINED, width: 900)
            }
          }
        }
        repeaterBenefits {
          ctaLabelH3Benefits
          ctaLinkH3Benefits
          textH3Benefits
          titleH3Benefits
        }
        titleH2Products
        textH2Products
        repeaterProducts {
          iconProducts {
            localFile {
              childImageSharp {
                gatsbyImageData (layout: CONSTRAINED, width: 80)
              }
            }
          }
          ctaLabelH3Products
          ctaLinkH3Products
          textH3Products
          titleH3Products
          visualProducts {
            localFile {
              childImageSharp {
                gatsbyImageData (layout: CONSTRAINED, width: 900, aspectRatio: 1.5)
              }
            }
          }
        }
        titleH2Services
        textH2Services
        repeaterServices {
          titleH3Services
          textH3Services
          ctaLabelH3Services
          ctaLinkH3Services
        }
        titleH2Specialties
        textH2Specialties
        titleBoxCtaSpecialties
        textBoxCtaSpecialties
        repeaterSpecialties {
          ctaLabelH3Specialties
          ctaLinkH3Specialties
          textH3Specialties
          titleH3Specialties
        }
      }
      seo {
        metaDesc
        title
      }
    }
    lastestArticle: allWpPost(limit: 1, sort: {fields: [date], order:DESC}) {
      edges {
        node {
          title
          slug
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`
/* Export page informations */
export default IndexPage
